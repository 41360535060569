














































































































import { Component, Prop, Vue } from 'vue-property-decorator'
import VsFilterRule, { IFilterRule } from '@/modules/lists/components/queryBuilder/VsFilterRule/Index.vue'
import VsRuleContact from '@/modules/lists/components/queryBuilder/VsRuleContact/Index.vue'
import VsRuleCustomField from '@/modules/lists/components/queryBuilder/VsRuleCustomField/Index.vue'
import VsRuleTags from '@/modules/lists/components/queryBuilder/VsRuleTags/Index.vue'
import VsRuleEvents from '@/modules/lists/components/queryBuilder/VsRuleEvents/Index.vue'
import VsRuleSaved from '@/modules/lists/components/queryBuilder/VsRuleSaved/Index.vue'
import VsDropdownButton from '@/components/VsDropdownButton/Index.vue'
import { UserModule } from '@/store/modules/user'

export interface IFilterGroup {
    type: 'group'
    logicalOperator: 'and' | 'or'
    children: IFilterGroup[] | IFilterRule []
}

@Component({
    name: 'VsFilterGroup',
    components: {
        VsFilterRule,
        VsDropdownButton,
        VsRuleCustomField,
        VsRuleTags,
        VsRuleEvents,
        VsRuleContact,
        VsRuleSaved,
    },
})
export default class extends Vue {
    @Prop({ required: false, default: null }) filter!: IFilterGroup
    @Prop({ required: false, default: () => [] }) customFields!: any[]
    @Prop({ required: false, default: false, type: Boolean }) notDeletable!: boolean
    @Prop({ required: false, default: 1 }) nestedLevel!: number
    private loading = false

    get listId () {
        return this.$route?.params?.listId
    }

    get user () {
        return UserModule.user
    }

    get isAddGroupDisabled () {
        return this.nestedLevel > 1
    }

    get isAddSavedRulesDisabled () {
        return this.nestedLevel > 1
    }

    addRule (query: any) {
        this.$emit('filter-changed', {
            ...this.filter,
            children: [
                ...this.filter.children,
                {
                    type: 'rule',
                    query,
                },
            ],
        })
    }

    addGroup () {
        this.$emit('filter-changed', {
            ...this.filter,
            children: [
                ...this.filter.children,
                {
                    type: 'group',
                    logicalOperator: 'and',
                    children: [],
                },
            ],
        })
    }

    removeFilter (index: number) {
        const newChilden = [
            ...this.filter.children,
        ]
        newChilden.splice(index, 1)
        this.$emit('filter-changed', {
            ...this.filter,
            children: newChilden,
        })
    }

    setLogicalOperator (value: string) {
        this.$emit('filter-changed', {
            ...this.filter,
            logicalOperator: value,
        })
    }

    setFilter (value: any, index: number) {
        const newChilden = [
            ...this.filter.children,
        ]
        newChilden[index] = value
        this.$emit('filter-changed', {
            ...this.filter,
            children: newChilden,
        })
    }

    save () {
        return ''
    }
}
