







































import { Component, Vue } from 'vue-property-decorator'
import { UserModule } from '@/store/modules/user'

interface QuerySavedFilter {
    entity: 'savedFilter'
    field: string // saved filter id
}

@Component({
    name: 'VsRuleSaved',
})
export default class extends Vue {
    open = false
    savedFilters: any[] = []
    private loading = false
    query: QuerySavedFilter = {
        entity: 'savedFilter',
        field: '',
    }

    $refs!: any

    get listId () {
        return this.$route?.params?.listId
    }

    get user () {
        return UserModule.user
    }

    get savedFiltersOptions () {
        return this.savedFilters.map(el => {
            return {
                label: el.name,
                value: el.id,
            }
        })
    }

    async addRule () {
        const valid = await this.$refs.addQueryRuleCustomFieldForm.validate()
        if (!valid) return
        this.$emit('submit', this.query)
        this.closeModal()
    }

    public closeModal () {
        this.open = false
    }

    public openModal () {
        this.query = {
            entity: 'savedFilter',
            field: '',
        }
        this.open = true
        this.getSavedFilters('')
    }

    private async getSavedFilters (search: string) {
        this.loading = true
        console.log(search)
        try {
            this.savedFilters = [
                {
                    id: '123',
                    name: 'Filtro contatti malvagi',
                },
                {
                    id: '124',
                    name: 'Filtro contatti furbetti',
                },
            ]
        } catch (e) {
            this.savedFilters = []
        }
        this.loading = false
    }

    formatBadgeLabel (item: any) {
        if (!item) return ''
        return this.savedFiltersOptions.find((el: any) => el.value === item)?.label || `Etichetta con id ${item} non trovata`
    }
}
