













































import { Component, Prop, Vue } from 'vue-property-decorator'

@Component({
    name: 'VsRuleValue',
})
export default class extends Vue {
    @Prop({ required: false, default: '' }) value!: any
    @Prop({ required: false, default: () => [] }) options!: string[]
    @Prop({ required: false, default: 'string' }) type!: string
    @Prop({ required: false, default: false }) multi!: boolean
    @Prop({ required: false, default: [] }) errors!: boolean

    private loading = false
}
