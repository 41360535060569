


































































import { Component, Prop, Vue } from 'vue-property-decorator'
import { UserModule } from '@/store/modules/user'
import VsRuleValue from '@/modules/lists/components/queryBuilder/VsRuleValue/Index.vue'
import VsCustomFieldGenerator from '@/modules/lists/components/VsCustomFieldGenerator/Index.vue'
import { CustomFieldTypeEnum } from '@/utils/customFields'

interface QueryCustomField {
    entity: 'customField'
    field: string // CustomFIeldId
    operator: string // 'eq' | 'notEq' | 'lg' | 'lge' | 'gt' | 'gte | 'like' | 'notLike' | 'in' | 'nin' /depends on customfield type
    value: any
}

@Component({
    name: 'VsRuleCustomField',
    components: {
        VsRuleValue,
        VsCustomFieldGenerator,
    },
})
export default class extends Vue {
    @Prop({ required: false, default: () => [] }) customFields!: any[]
    open = false
    private loading = false
    allOperators = ['eq', 'notEq', 'lg', 'lge', 'gt', 'gte', 'like', 'notLike', 'in', 'nin', 'empty', 'notEmpty']
    dateOperators = ['eq', 'notEq', 'lg', 'lge', 'gt', 'gte', 'empty', 'notEmpty']
    stringOperators = ['eq', 'notEq', 'like', 'notLike', 'empty', 'notEmpty']
    stringOperatorsWithOptions = ['eq', 'notEq', 'in', 'nin', 'empty', 'notEmpty']
    numberOperators = ['eq', 'notEq', 'lg', 'lge', 'gt', 'gte', 'empty', 'notEmpty']
    numberOperatorsWithOptions = ['eq', 'notEq', 'lg', 'lge', 'gt', 'gte', 'in', 'nin', 'empty', 'notEmpty']

    query: QueryCustomField = {
        entity: 'customField',
        field: '',
        operator: 'eq',
        value: '',
    }

    $refs!: any

    get customFieldsOptions () {
        return this.customFields.map(el => {
            return {
                label: el.name,
                value: el.id,
            }
        })
    }

    get customFieldOperators (): string[] {
        if (!this.currentCustomField) return []

        switch (this.currentCustomField.type) {
            case CustomFieldTypeEnum.booleanCheckbox:
                return ['eq', 'notEq', 'empty', 'notEmpty']
            case CustomFieldTypeEnum.dateField:
            case CustomFieldTypeEnum.timeField:
                return this.dateOperators
            case CustomFieldTypeEnum.checkboxes:
                return ['in', 'nin', 'empty', 'notEmpty']
            default:
                return this.stringOperators
        }
    }

    get defaultCustomField () {
        return {
            id: 0,
            recipient_id: 426,
            name: '',
            type: 'Single line',
            placeholder: '',
            default_value: '',
            field_options: [],
            other_options: ['', '', '', '', ''],
            visibility: 'Public',
            global: false,
            validation: {
                required: false,
                unique: false,
                method: 'Disabled',
                rule: '',
            },
        }
    }

    get currentCustomField () {
        const cf = this.customFields.find(el => el.id === this.query.field) || this.defaultCustomField
        cf.validation.required = true
        return cf
    }

    get customFieldValueOptions (): any[] {
        if (!this.currentCustomField) return []
        return this.currentCustomField.field_options
    }

    get operatorsOption () {
        return this.customFieldOperators.map(el => {
            return {
                value: el,
                label: this.$t(`lists.filters.operators.${el}`),
            }
        })
    }

    get listId () {
        return this.$route?.params?.listId
    }

    get user () {
        return UserModule.user
    }

    setDefaultValue (field: string) {
        const selectedCustomField = this.customFields.find(el => el.id === field)
        if (!selectedCustomField) return
        switch (selectedCustomField.type) {
            case CustomFieldTypeEnum.booleanCheckbox:
                this.query.operator = 'eq'
                this.query.value = false
                break
            case CustomFieldTypeEnum.checkboxes:
                this.query.operator = 'in'
                this.query.value = []
                break
            default:
                this.query.operator = 'eq'
                this.query.value = ''
        }
    }

    async addRule () {
        const valid = await this.$refs.addQueryRuleCustomFieldForm.validate()
        if (!valid) return
        this.$emit('submit', this.query)
        this.closeModal()
    }

    public closeModal () {
        this.open = false
    }

    public openModal () {
        this.query = {
            entity: 'customField',
            field: '',
            operator: 'eq',
            value: '',
        }
        this.open = true
    }
}
