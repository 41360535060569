



























































import { Component, Vue } from 'vue-property-decorator'
import { UserModule } from '@/store/modules/user'
import VsRuleValue from '@/modules/lists/components/queryBuilder/VsRuleValue/Index.vue'
import VsCustomFieldGenerator from '@/modules/lists/components/VsCustomFieldGenerator/Index.vue'
import { getTags } from '@/api/consoleApi/recipients'

interface QueryTags {
    entity: 'tags'
    operator: string // 'eq' | 'notEq' | 'in' | 'nin'
    value: any
}

@Component({
    name: 'VsRuleTags',
    components: {
        VsRuleValue,
        VsCustomFieldGenerator,
    },
})
export default class extends Vue {
    open = false
    private loading = false
    tags: any[] = []
    query: QueryTags = {
        entity: 'tags',
        operator: 'in',
        value: '',
    }

    $refs!: any

    get tagsOptions () {
        return this.tags.map(el => {
            return {
                label: el.name,
                value: el.id,
            }
        })
    }

    get customFieldOperators (): string[] {
        return ['in', 'nin']
    }

    get operatorsOption () {
        return this.customFieldOperators.map(el => {
            return {
                value: el,
                label: this.$t(`lists.filters.operators.${el}`),
            }
        })
    }

    get listId () {
        return this.$route?.params?.listId
    }

    get user () {
        return UserModule.user
    }

    async addRule () {
        const valid = await this.$refs.addQueryRuleCustomFieldForm.validate()
        if (!valid) return
        this.$emit('submit', this.query)
        this.closeModal()
    }

    public closeModal () {
        this.open = false
    }

    public openModal () {
        this.tags = []
        this.query = {
            entity: 'tags',
            operator: 'in',
            value: '',
        }
        this.open = true
        this.getTags('')
    }

    private async getTags (search: string) {
        this.loading = true
        try {
            const resp = await getTags(
                this.listId,
                {
                    limit: 50,
                    search: `name:${search}`,
                    searchFields: 'name:like',
                })
            this.tags = resp.data.data
        } catch (e) {
            this.tags = []
        }
        this.loading = false
    }

    formatBadgeLabel (item: any) {
        if (!item) return ''
        return this.tagsOptions.find((el: any) => el.value === item)?.label || `Etichetta con id ${item} non trovata`
    }
}
