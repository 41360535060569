

































































































































































































































import { Component, Vue } from 'vue-property-decorator'
import { UserModule } from '@/store/modules/user'
import VsRuleValue from '@/modules/lists/components/queryBuilder/VsRuleValue/Index.vue'
import VsCustomFieldGenerator from '@/modules/lists/components/VsCustomFieldGenerator/Index.vue'

interface QueryEvents {
    entity: 'events'
    eventType: string // 'click' | 'open' | 'sent'
    containerType: string // 'automation' | 'campaign'
    containerId: string
    contentId: ''
    triggerTimes: number
    triggersOperator: string
    lastEventRange: string
    linkOperator: string
    linkValue: string
}

@Component({
    name: 'VsRuleEvents',
    components: {
        VsRuleValue,
        VsCustomFieldGenerator,
    },
})
export default class extends Vue {
    open = false
    sources = []
    private loading = false
    triggerType = 'trigger'
    query: QueryEvents = {
        entity: 'events',
        eventType: 'click', // 'click' | 'open' | 'sent'
        triggerTimes: 0,
        triggersOperator: 'eq',
        containerType: '',
        containerId: '',
        contentId: '',
        lastEventRange: 'week',
        linkOperator: '',
        linkValue: '',
    }

    $refs!: any

    get actionsOption () {
        return [
            {
                label: 'Cliccato',
                value: 'click',
            },
            {
                label: 'Aperto',
                value: 'open',
            },
            {
                label: 'Ricevuto un messaggio',
                value: 'sent',
            },
        ]
    }

    get lastEventRangeOptions () {
        return [
            {
                label: 'Ultima settimana',
                value: 'week',
            },
            {
                label: 'Ultimo mese',
                value: 'month',
            },
        ]
    }

    get containerTypeOption () {
        return [
            {
                label: 'Qualsiasi contenuto',
                value: '',
            },
            {
                label: 'Campagna',
                value: 'campaign',
            },
            {
                label: 'Automation',
                value: 'automation',
            },
        ]
    }

    get containerIdOption () {
        return [
            {
                label: 'Campagna 1',
                value: '1234',
            },
            {
                label: 'Campagna 2',
                value: '1235',
            },
        ]
    }

    get contentIdOption () {
        return [
            {
                label: 'Email primavera 1',
                value: '1234',
            },
            {
                label: 'Email benvenuto 2',
                value: '1235',
            },
        ]
    }

    get linkOperatorOptions () {
        const linkOperators = ['all', 'eq', 'notEq', 'like', 'notLike']
        return linkOperators.map(el => {
            return {
                value: el === 'all' ? '' : el,
                label: el === 'all' ? 'qualsiasi' : this.$t(`lists.filters.operators.${el}`),
            }
        })
    }

    get triggersOperatorOptions () {
        const triggersOperators = ['eq', 'notEq', 'lg', 'lge', 'gt', 'gte']
        return triggersOperators.map(el => {
            return {
                value: el,
                label: this.$t(`lists.filters.operators.${el}`),
            }
        })
    }

    get listId () {
        return this.$route?.params?.listId
    }

    get user () {
        return UserModule.user
    }

    async addRule () {
        const valid = await this.$refs.addQueryRuleEventsForm.validate()
        if (!valid) return
        if (this.triggerType === 'range') {
            this.query.triggersOperator = 'eq'
            this.query.triggerTimes = 1
        } else {
            this.query.lastEventRange = ''
        }

        if (!this.query.containerType) {
            this.query.containerId = ''
            this.query.contentId = ''
        }
        this.$emit('submit', this.query)
        this.closeModal()
    }

    public closeModal () {
        this.open = false
    }

    public openModal () {
        this.query = {
            entity: 'events',
            eventType: 'click', // 'click' | 'open' | 'sent'
            triggerTimes: 0,
            triggersOperator: 'eq',
            containerType: '',
            containerId: '',
            contentId: '',
            lastEventRange: 'week',
            linkOperator: '',
            linkValue: '',
        }
        this.open = true
        this.getSources()
    }

    private async getSources () {
        // try {
        //     if (this.query.source === 'email') {
        //         const resp = await getEmails()
        //         console.log(resp.data, 'email')
        //     } else {
        //         const resp = await getAllSms()
        //         console.log(resp.data, 'sms')
        //     }
        // } catch (e) {
        //     console.log(e)
        // }
    }

    private async containerTypeChanged (type: string) {
        this.query.containerId = ''
        this.query.contentId = ''
        this.getSources()
    }
}
