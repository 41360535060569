
















































































import { Component, Vue } from 'vue-property-decorator'
import { UserModule } from '@/store/modules/user'
import VsRuleValue from '@/modules/lists/components/queryBuilder/VsRuleValue/Index.vue'

interface QueryContact {
    entity: 'contact' | 'suppression'
    field: string // SubscriberID | SubscriptionStatus | Opt-In+Pending | SubscriptionIP | OptInDate | SubscriptionDate | OptInIP
    operator: string // 'eq' | 'notEq' | 'lg' | 'lge' | 'gt' | 'gte | 'like' | 'notLike' | 'in' | 'nin'
    value: any
}

@Component({
    name: 'VsRuleContact',
    components: {
        VsRuleValue,
    },
})
export default class extends Vue {
    open = false
    private loading = false
    allOperators = ['eq', 'notEq', 'lg', 'lge', 'gt', 'gte', 'like', 'notLike', 'in', 'nin', 'empty', 'notEmpty']
    dateOperators = ['eq', 'notEq', 'lg', 'lge', 'gt', 'gte', 'empty', 'notEmpty']
    stringOperators = ['eq', 'notEq', 'like', 'notLike', 'empty', 'notEmpty']
    stringOperatorsWithOptions = ['eq', 'notEq', 'in', 'nin', 'empty', 'notEmpty']
    numberOperators = ['eq', 'notEq', 'lg', 'lge', 'gt', 'gte', 'empty', 'notEmpty']
    numberOperatorsWithOptions = ['eq', 'notEq', 'lg', 'lge', 'gt', 'gte', 'in', 'nin', 'empty', 'notEmpty']

    query: QueryContact = {
        entity: 'contact',
        field: 'SubscriberID',
        operator: 'eq',
        value: '',
    }

    $refs!: any

    get contactOperators (): string[] {
        switch (this.query.field) {
            case 'SubscriberID':
            case 'SubscriptionIP':
            case 'OptInIP':
                return this.stringOperators
            case 'SubscriptionStatus':
                return ['eq', 'notEq', 'empty', 'notEmpty']
            case 'Opt-In+Pending':
            case 'OptInDate':
            case 'SubscriptionDate':
            case 'UnsubscriptionDate':
                return this.dateOperators
            case 'suppression':
                return ['empty', 'notEmpty']
            default:
                return this.allOperators
        }
    }

    get contactType (): string {
        switch (this.query.field) {
            case 'Opt-In+Pending':
            case 'OptInDate':
            case 'SubscriptionDate':
            case 'UnsubscriptionDate':
                return 'date'
            default:
                return 'string'
        }
    }

    get contactOptions (): any[] {
        switch (this.query.field) {
            case 'SubscriptionStatus':
                return [
                    {
                        label: 'Iscritto',
                        value: 'Subscribed',
                    },
                    {
                        label: 'Disiscritto',
                        value: 'Unsubscribed',
                    },
                    {
                        label: 'Attesa conferma iscrizione',
                        value: 'Opt in Pending',
                    },
                ]
            default:
                return []
        }
    }

    get operatorsOption () {
        return this.contactOperators.map(el => {
            return {
                value: el,
                label: this.$t(`lists.filters.operators.${el}`),
            }
        })
    }

    get listId () {
        return this.$route?.params?.listId
    }

    get user () {
        return UserModule.user
    }

    setDefaultValue (field: string) {
        switch (field) {
            case 'suppression':
                this.query.value = ''
                this.query.operator = 'empty'
                break
            default:
                this.query.value = ''
        }
    }

    async addRule () {
        const valid = await this.$refs.addQueryRuleForm.validate()
        if (!valid) return
        if (this.query.field === 'suppression') {
            this.query.entity = 'suppression'
        }
        this.$emit('submit', this.query)
        this.closeModal()
    }

    public closeModal () {
        this.open = false
    }

    public openModal () {
        this.query = {
            entity: 'contact',
            field: 'SubscriberID',
            operator: 'eq',
            value: '',
        }
        this.open = true
    }
}
