var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('VsModal',{attrs:{"heading":"Aggiungi regola sulle azioni del contatto","maxWidth":"900px"},scopedSlots:_vm._u([{key:"actionLeft",fn:function(){return [_c('VsButton',{attrs:{"aspect":"secondary"},on:{"click":_vm.closeModal}},[_vm._v("Chiudi")])]},proxy:true},{key:"actionRight",fn:function(){return [_c('VsButton',{on:{"click":_vm.addRule}},[_vm._v("Aggiungi regola")])]},proxy:true}]),model:{value:(_vm.open),callback:function ($$v) {_vm.open=$$v},expression:"open"}},[_c('ValidationObserver',{ref:"addQueryRuleEventsForm"},[_c('div',[_c('VsLabel',{staticClass:"vs-mb-2"},[_vm._v(" Il contatto ha ")]),_c('div',{staticClass:"vs-flex vs-gap-4 vs-mb-4"},[_c('ValidationProvider',{attrs:{"name":"Azione","vid":'queryRuleAction',"slim":"","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('VsSelect',{staticClass:"vs-flex-1 vs-mb-4",attrs:{"labelHidden":"","error":errors.length > 0,"errorMessage":errors[0],"boundary":"window","options":_vm.actionsOption},model:{value:(_vm.query.eventType),callback:function ($$v) {_vm.$set(_vm.query, "eventType", $$v)},expression:"query.eventType"}})]}}])}),_c('VsSelect',{staticClass:"vs-flex-1 vs-mb-4",attrs:{"labelHidden":"","boundary":"window","options":[
                        {
                            label: 'Un numero di volte',
                            value: 'trigger',
                        },
                        {
                            label: 'Almeno una volta nel',
                            value: 'range',
                        } ]},model:{value:(_vm.triggerType),callback:function ($$v) {_vm.triggerType=$$v},expression:"triggerType"}}),(_vm.triggerType === 'trigger')?_c('ValidationProvider',{attrs:{"name":"Operatore","vid":'queryRuleOperator',"slim":"","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                    var errors = ref.errors;
return [_c('VsSelect',{staticClass:"vs-flex-1",attrs:{"placeholder":"Seleziona l'operatore","labelHidden":"","boundary":"window","options":_vm.triggersOperatorOptions,"error":errors.length > 0,"errorMessage":errors[0]},model:{value:(_vm.query.triggersOperator),callback:function ($$v) {_vm.$set(_vm.query, "triggersOperator", $$v)},expression:"query.triggersOperator"}})]}}],null,false,3642550898)}):_vm._e(),(_vm.triggerType === 'trigger')?_c('ValidationProvider',{attrs:{"name":"Quantità","vid":'queryRuleTriggers',"slim":"","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                    var errors = ref.errors;
return [_c('VsInput',{staticClass:"vs-flex-1 vs-mb-4",attrs:{"type":"number","variant":"number","min":0,"labelHidden":"","error":errors.length > 0,"errorMessage":errors[0]},model:{value:(_vm.query.triggerTimes),callback:function ($$v) {_vm.$set(_vm.query, "triggerTimes", _vm._n($$v))},expression:"query.triggerTimes"}})]}}],null,false,1676463516)}):_vm._e(),(_vm.triggerType === 'range')?_c('ValidationProvider',{attrs:{"name":"Range","vid":'queryRuleLastEventRange',"slim":"","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                    var errors = ref.errors;
return [_c('VsSelect',{staticClass:"vs-flex-1",attrs:{"placeholder":"Seleziona l'operatore","label":"","boundary":"window","options":_vm.lastEventRangeOptions,"error":errors.length > 0,"errorMessage":errors[0]},model:{value:(_vm.query.lastEventRange),callback:function ($$v) {_vm.$set(_vm.query, "lastEventRange", $$v)},expression:"query.lastEventRange"}})]}}],null,false,3033413496)}):_vm._e()],1),_c('div',{staticClass:"vs-mt-4"},[_c('VsLabel',{staticClass:"vs-mb-2"},[_vm._v(" Il contenuto ")]),_c('div',{staticClass:"vs-flex vs-gap-4 vs-mb-4"},[_c('ValidationProvider',{attrs:{"name":"contenuto","vid":'queryRuleContainerType',"slim":"","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                    var errors = ref.errors;
return [_c('VsSelect',{staticClass:"vs-flex-1",attrs:{"labelHidden":"","error":errors.length > 0,"errorMessage":errors[0],"boundary":"window","options":_vm.containerTypeOption},on:{"input":_vm.containerTypeChanged},model:{value:(_vm.query.containerType),callback:function ($$v) {_vm.$set(_vm.query, "containerType", $$v)},expression:"query.containerType"}})]}}])}),_c('div',{staticClass:"vs-flex-1"},[(_vm.query.containerType)?_c('ValidationProvider',{attrs:{"name":"Contenuto","vid":'queryRuleContainerId',"slim":"","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                    var errors = ref.errors;
return [_c('VsSelect',{staticClass:"vs-flex-1",attrs:{"placeholder":"Seleziona il contenuto","labelHidden":"","boundary":"window","options":_vm.containerIdOption,"error":errors.length > 0,"errorMessage":errors[0]},model:{value:(_vm.query.containerId),callback:function ($$v) {_vm.$set(_vm.query, "containerId", $$v)},expression:"query.containerId"}})]}}],null,false,4125586009)}):_vm._e()],1),_c('div',{staticClass:"vs-flex-1"},[(_vm.query.containerType === 'automation' && _vm.query.containerId)?_c('ValidationProvider',{attrs:{"name":"Contenuto","vid":'queryRuleContentId',"slim":"","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                    var errors = ref.errors;
return [_c('VsSelect',{staticClass:"vs-flex-1",attrs:{"placeholder":"Seleziona il messaggio","labelHidden":"","boundary":"window","options":_vm.contentIdOption,"error":errors.length > 0,"errorMessage":errors[0]},model:{value:(_vm.query.contentId),callback:function ($$v) {_vm.$set(_vm.query, "contentId", $$v)},expression:"query.contentId"}})]}}],null,false,475862565)}):_vm._e()],1)],1),(_vm.query.eventType === 'click' && _vm.query.containerId)?_c('VsLabel',{staticClass:"vs-mb-2"},[_vm._v(" Un link ")]):_vm._e(),(_vm.query.eventType === 'click' && _vm.query.containerId)?_c('div',{staticClass:"vs-flex vs-gap-4 vs-mb-4"},[_c('ValidationProvider',{attrs:{"name":"Operatore","vid":'queryRuleLinkOperator',"slim":"","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                    var errors = ref.errors;
return [_c('VsSelect',{staticClass:"vs-flex-1",attrs:{"placeholder":"Seleziona l'operatore","labelHidden":"","boundary":"window","options":_vm.linkOperatorOptions,"error":errors.length > 0,"errorMessage":errors[0]},model:{value:(_vm.query.operator),callback:function ($$v) {_vm.$set(_vm.query, "operator", $$v)},expression:"query.operator"}})]}}],null,false,2753575986)}),_c('div',{staticClass:"vs-flex-1"},[(_vm.query.operator)?_c('ValidationProvider',{attrs:{"name":"Valore","vid":'queryRuleLinkValue',"slim":"","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                    var errors = ref.errors;
return [_c('VsInput',{attrs:{"labelHidden":"","boundary":"window","error":errors.length > 0,"errorMessage":errors[0]},model:{value:(_vm.query.value),callback:function ($$v) {_vm.$set(_vm.query, "value", $$v)},expression:"query.value"}})]}}],null,false,3753218341)}):_vm._e()],1)],1):_vm._e()],1)],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }