

























import { Component, Prop, Vue } from 'vue-property-decorator'
import { UserModule } from '@/store/modules/user'

export interface IFilterRule {
    type: 'rule'
    query: any
}

@Component({
    name: 'VsFilterRule',
})
export default class extends Vue {
    @Prop({ required: false, default: null }) filter!: IFilterRule
    @Prop({ required: false, default: () => [] }) customFields!: any[]
    private loading = false

    get fieldName () {
        if (!this.filter.query.entity) return 'Regola non trovata'
        if (this.filter.query.entity === 'customField') {
            return this.customFields.find(el => el.id === this.filter.query.field)?.name || 'Campo personalizzato non trovato'
        }
        if (this.filter.query.entity === 'contact') {
            return this.filter.query.field
        }
        if (this.filter.query.entity === 'tags') {
            return 'Etichette'
        }

        if (this.filter.query.entity === 'tags') {
            return this.filter.query.eventType
        }

        return ''
    }

    get listId () {
        return this.$route?.params?.listId
    }

    get user () {
        return UserModule.user
    }

    removeRule () {
        this.$emit('filter-removed')
    }
}
