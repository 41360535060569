var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('VsModal',{attrs:{"heading":"Aggiungi regola sui campi fissi del contatto","maxWidth":"900px"},scopedSlots:_vm._u([{key:"actionLeft",fn:function(){return [_c('VsButton',{attrs:{"aspect":"secondary"},on:{"click":_vm.closeModal}},[_vm._v("Chiudi")])]},proxy:true},{key:"actionRight",fn:function(){return [_c('VsButton',{on:{"click":_vm.addRule}},[_vm._v("Aggiungi regola")])]},proxy:true}]),model:{value:(_vm.open),callback:function ($$v) {_vm.open=$$v},expression:"open"}},[_c('ValidationObserver',{ref:"addQueryRuleForm"},[_c('VsLabel',{staticClass:"vs-mb-2"},[_vm._v("Campo contatto")]),(_vm.query.entity === 'contact')?_c('div',{staticClass:"vs-flex vs-gap-4"},[_c('VsSelect',{attrs:{"boundary":"window","labelHidden":"","options":[
                    {
                        label: 'Id contatto',
                        value: 'SubscriberID',
                    },
                    {
                        label: 'Stato contatto',
                        value: 'SubscriptionStatus',
                    },
                    {
                        label: 'Data opt-in',
                        value: 'OptInDate',
                    },
                    {
                        label: 'Data iscrizione',
                        value: 'SubscriptionDate',
                    },
                    {
                        label: 'Data disiscrizione',
                        value: 'UnsubscriptionDate',
                    },
                    {
                        label: 'Ip iscrizione',
                        value: 'SubscriptionIP',
                    },
                    {
                        label: 'Ip opt-in',
                        value: 'OptInIP',
                    },
                    {
                        label: 'Stato soppressione',
                        value: 'suppression',
                    } ]},on:{"input":_vm.setDefaultValue},model:{value:(_vm.query.field),callback:function ($$v) {_vm.$set(_vm.query, "field", $$v)},expression:"query.field"}}),_c('VsSelect',{attrs:{"labelHidden":"","boundary":"window","options":_vm.operatorsOption},model:{value:(_vm.query.operator),callback:function ($$v) {_vm.$set(_vm.query, "operator", $$v)},expression:"query.operator"}}),(_vm.query.operator !== 'empty' && _vm.query.operator !== 'notEmpty')?_c('ValidationProvider',{attrs:{"name":"Valore","vid":'queryRuleValue',"slim":"","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var errors = ref.errors;
return [_c('VsRuleValue',{attrs:{"errors":errors,"type":_vm.contactType,"options":_vm.contactOptions},model:{value:(_vm.query.value),callback:function ($$v) {_vm.$set(_vm.query, "value", $$v)},expression:"query.value"}})]}}],null,false,3137501817)}):_vm._e()],1):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }