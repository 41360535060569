





















































import { Component, Prop, Vue } from 'vue-property-decorator'
import { UserModule } from '@/store/modules/user'
import VsFilterGroup, { IFilterGroup } from '@/modules/lists/components/queryBuilder/VsFilterGroup/Index.vue'

@Component({
    name: 'VsContactsFilterMock',
    components: {
        VsFilterGroup,
    },
})
export default class extends Vue {
    @Prop({ required: false, default: () => [] }) customFields!: any[]
    private open = false
    private loading = false

    filters: [IFilterGroup] = [{
        type: 'group',
        logicalOperator: 'and', // and | or
        children: [],
    }]

    private hasRules (filters: any[]): boolean {
        for (const filter of filters) {
            if (filter.type === 'rule') return true
            if (filter.children.length === 0) continue
            const resp = this.hasRules(filter.children)
            if (resp) return true
        }
        return false
    }

    get listId () {
        return this.$route?.params?.listId
    }

    get user () {
        return UserModule.user
    }

    setFilter (value: any) {
        this.filters = [value]
    }

    public closeModal () {
        this.open = false
    }

    public openModal () {
        this.filters = [{
            type: 'group',
            logicalOperator: 'and', // and | or
            children: [],
        }]
        this.open = true
    }

    private save () {
        alert('bravo hai salvato')
    }
}
